import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './OrderApp.css';
import Login from './Login';

const API_URL = 'https://sweetleaf.gr/wp-json/wc/v3/orders';
const PRODUCT_URL = 'https://sweetleaf.gr/wp-json/wc/v3/products';
const CONSUMER_KEY = 'ck_20cb7ebbc919633bcdd92761f86108b80925dc68';
const CONSUMER_SECRET = 'cs_15fc5423ab551e020478405d63d7f0ebe0427398';
const SOUND_FILE = '/ring.mp3';

const OrderApp = ({ isLoggedIn, setIsLoggedIn }) => {
    const [newOrders, setNewOrders] = useState([]);
    const [inProcessOrders, setInProcessOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [totalSalesToday, setTotalSalesToday] = useState(0.0);
    const [soundPlaying, setSoundPlaying] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [productVariations, setProductVariations] = useState({});
    const audioRef = useRef(null);

    const initializeAudio = useCallback(() => {
        if (!audioRef.current) {
            audioRef.current = new Audio(SOUND_FILE);
            audioRef.current.loop = true;
        }

        document.removeEventListener('click', initializeAudio); // Αφαιρούμε το event listener μετά την πρώτη αλληλεπίδραση
    }, []);

    useEffect(() => {
        document.addEventListener('click', initializeAudio);
        return () => document.removeEventListener('click', initializeAudio);
    }, [initializeAudio]);

    const updateOrderLists = useCallback((orders) => {
        const newOrdersList = [];
        const inProcessOrdersList = [];
        const completedOrdersList = [];
        let total = 0;

        const orderIds = new Set(); // Θα χρησιμοποιηθεί για την αφαίρεση διπλών εγγραφών

        orders.forEach(order => {
            const totalAmount = parseFloat(order.total.replace('€', '').trim());
            if (order.status === 'processing') {
                if (!orderIds.has(order.id)) {
                    newOrdersList.push(order);
                    orderIds.add(order.id);
                }
                total += totalAmount;
            } else if (order.status === 'on-hold') {
                if (!orderIds.has(order.id)) {
                    inProcessOrdersList.push(order);
                    orderIds.add(order.id);
                }
            } else if (order.status === 'completed') {
                completedOrdersList.push(order);
                total += totalAmount;
            }
        });

        setNewOrders(newOrdersList);
        setInProcessOrders(inProcessOrdersList);
        setCompletedOrders(completedOrdersList);
        setTotalSalesToday(total);

        if (newOrdersList.length > 0 && !soundPlaying) {
            if (audioRef.current) {  // Προσθέτουμε τον έλεγχο εδώ
                audioRef.current.play().catch(err => console.error('Failed to play sound:', err));
                setSoundPlaying(true);
            }
        } else if (newOrdersList.length === 0 && soundPlaying) {
            if (audioRef.current) {  // Προσθέτουμε τον έλεγχο εδώ
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setSoundPlaying(false);
            }
        }
    }, [soundPlaying]);

    const fetchOrders = useCallback(async () => {
        try {
            const response = await axios.get(API_URL, {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)
                }
            });
            const orders = response.data;
            updateOrderLists(orders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }, [updateOrderLists]);

    const fetchProductVariations = async (productId) => {
        try {
            const response = await axios.get(`${PRODUCT_URL}/${productId}/variations`, {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching product variations:', error);
            return [];
        }
    };

    const updateOrderStatus = async (orderId, newStatus) => {
        try {
            await axios.put(`${API_URL}/${orderId}`, { status: newStatus }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)
                }
            });
            fetchOrders();  // Ανανεώνουμε τις παραγγελίες μετά την ενημέρωση
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const handleOrderClick = async (order) => {
        setSelectedOrder(order);
        const variations = {};
        for (const item of order.line_items) {
            variations[item.id] = await fetchProductVariations(item.product_id);
        }
        setProductVariations(variations);

        if (soundPlaying) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setSoundPlaying(false);
        }
    };

    const handleViewOrder = async (orderId) => {
        const orderToProcess = newOrders.find(order => order.id === orderId);
        if (orderToProcess) {
            await updateOrderStatus(orderId, 'on-hold');
            setInProcessOrders(prevOrders => [...prevOrders, { ...orderToProcess, status: 'on-hold' }]);
            setNewOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
            setSelectedOrder(orderToProcess);
        }
    };

    const handleCompleteOrder = async (orderId) => {
        const orderToComplete = inProcessOrders.find(order => order.id === orderId);
        if (orderToComplete) {
            await updateOrderStatus(orderId, 'completed');
            setCompletedOrders(prevOrders => [...prevOrders, { ...orderToComplete, status: 'completed' }]);
            setInProcessOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
        }
    };

    const handlePrint = () => {
        if (selectedOrder) {
            window.print();
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp * 1000 > Date.now()) {
                    setIsLoggedIn(true);
                } else {
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Failed to decode token:', error);
                localStorage.removeItem('token');
                setIsLoggedIn(false);
            }
        }
    }, [setIsLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            fetchOrders();
            const intervalId = setInterval(fetchOrders, 60000);
            return () => clearInterval(intervalId);
        }
    }, [fetchOrders, isLoggedIn]);

    return (
        <div className="order-app">
            {isLoggedIn ? (
                <>
                    <h1>SweetLeaf Πρόγραμμα Παραγγελιών</h1>
                    <h2>Σύνολο Σήμερα: €{totalSalesToday.toFixed(2)}</h2>

                    <h3>Νέες Παραγγελίες</h3>
                    {newOrders.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Όνομα Πελάτη</th>
                                    <th>Δράση</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newOrders.map(order => (
                                    <tr key={`new-${order.id}`}>
                                        <td onClick={() => handleOrderClick(order)}>
                                            {order.billing.first_name} {order.billing.last_name} {order.total} €
                                        </td>
                                        <td>
                                            <button onClick={() => handleViewOrder(order.id)}>Για Παρασκευή</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Δεν υπάρχουν νέες παραγγελίες.</p>
                    )}

                    <h3>Σε Διαδικασία Παρασκευής</h3>
                    {inProcessOrders.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Όνομα Πελάτη</th>
                                    <th>Δράση</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inProcessOrders.map(order => (
                                    <tr key={`inprocess-${order.id}`}>
                                        <td onClick={() => handleOrderClick(order)}>
                                            {order.billing.first_name} {order.billing.last_name} {order.total} €
                                        </td>
                                        <td>
                                            <button onClick={() => handleCompleteOrder(order.id)}>Ολοκλήρωση</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Δεν υπάρχουν παραγγελίες σε διαδικασία παρασκευής.</p>
                    )}

                    <h3>Ολοκληρωμένες Παραγγελίες</h3>
                    {completedOrders.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Όνομα Πελάτη</th>
                                    <th>Δράση</th>
                                </tr>
                            </thead>
                            <tbody>
                                {completedOrders.map(order => (
                                    <tr key={order.id}>
                                        <td onClick={() => handleOrderClick(order)}>
                                            {order.billing.first_name} {order.billing.last_name} {order.total} €
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Δεν υπάρχουν ολοκληρωμένες παραγγελίες.</p>
                    )}

                    {selectedOrder && (
                        <div className="order-details">
                            <h3>Παραγγελία με ID: {selectedOrder.id}</h3>
                            <p>Ημερομηνία Δημιουργίας: {new Date(selectedOrder.date_created).toLocaleString()}</p>
                            <p>Σημείωση Πελάτη: {selectedOrder.customer_note}</p>
                            <h4>Παραγγελία:</h4>
                            {selectedOrder.line_items && selectedOrder.line_items.length > 0 ? (
                                <table className="product-table">
                                    <thead>
                                        <tr>
                                            <th>Όνομα Προϊόντος</th>
                                            <th>Ποσότητα</th>
                                            <th>Τιμή</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedOrder.line_items.map((item) => (
                                            <tr key={item.id}>
                                                <td>
                                                    {item.name}
                                                    {productVariations[item.product_id] && productVariations[item.product_id].length > 0 && (
                                                        <ul style={{ paddingLeft: '20px', fontSize: 'smaller' }}>
                                                            {productVariations[item.product_id].map(variation => (
                                                                <li key={variation.id}>
                                                                    {variation.attributes.map(attr => (
                                                                        <span key={attr.id}>
                                                                            {attr.name}: {attr.option}
                                                                        </span>
                                                                    ))}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    {item.meta_data && item.meta_data.length > 0 && item.meta_data.map(meta => {
                                                        if (meta.display_key === '_addon_items') {
                                                            return (
                                                                <ul key={meta.id} style={{ paddingLeft: '20px', fontSize: 'smaller' }}>
                                                                    {meta.display_value.map(addon => (
                                                                        <li key={addon.addon_item.name} className="addon-list-item">
                                                                            {decodeURIComponent(addon.addon_item.name)}: {decodeURIComponent(addon.addon_item.value)}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </td>
                                                <td>{item.quantity}</td>
                                                <td className="wfs_order_meta">{item.total} €</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>Δεν υπάρχουν προϊόντα στην παραγγελία.</p>
                            )}
                            <div className="order-summary">
                                <h4>Στοιχεία Πελάτη</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Όνομα Πελάτη</th>
                                            <td>{selectedOrder.billing.first_name} {selectedOrder.billing.last_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Διεύθυνση</th>
                                            <td>{selectedOrder.billing.address_1}, {selectedOrder.billing.city}, {selectedOrder.billing.state}, {selectedOrder.billing.postcode}</td>
                                        </tr>
                                        {selectedOrder.billing.address_2 && (
                                            <tr>
                                                <th>Όροφος</th>
                                                <td>{selectedOrder.billing.address_2}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>Τηλέφωνο</th>
                                            <td>{selectedOrder.billing.phone}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <h4>Πληροφορίες Παραγγελίας</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Ποσό</th>
                                            <td>{selectedOrder.total} €</td>
                                        </tr>
                                        <tr>
                                            <th>Έκπτωση</th>
                                            <td>{selectedOrder.discount_total} € </td>
                                        </tr>
                                        <tr>
                                            <th>Κατάσταση</th>
                                            <td>{selectedOrder.status}</td>
                                        </tr>
                                        <tr>
                                            <th>Μέθοδος Πληρωμής</th>
                                            <td>{selectedOrder.payment_method === 'cod' ? 'Μετρητά' : 'Κάρτα'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                   
                            <button onClick={handlePrint}>Εκτύπωση</button>
                        </div>
                    )}
                </>
            ) : (
                <Login onLogin={() => setIsLoggedIn(true)} />
            )}
        </div>
    );
};

export default OrderApp;
