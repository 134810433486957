import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import OrderApp from './OrderApp';
import ProductList from './ProductList';
import './App.css';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    return (
        <Router>
            <div className="App">
                <nav className="navbar">
                    <div className="navbar-container">
                        <Link to="/" className="navbar-logo">
                            <img src={`${process.env.PUBLIC_URL}/sw.png`} alt="SweetLeaf Logo" />
                        </Link>
                        <ul className="nav-menu">
                            <li className="nav-item">
                                <Link to="/" className="nav-links">Παραγγελίες</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/products" className="nav-links">Προϊόντα</Link>
                            </li>
                            {isLoggedIn && (
                                <li className="nav-item">
                                    <button onClick={handleLogout} className="nav-links">Αποσύνδεση</button>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
                <Routes>
                    <Route path="/" element={<OrderApp isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/products" element={<ProductList />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
