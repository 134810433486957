import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Βρείτε το root στοιχείο σας
const rootElement = document.getElementById('root');

// Δημιουργήστε το root χρησιμοποιώντας τη μέθοδο createRoot
const root = ReactDOM.createRoot(rootElement);

// Χρησιμοποιήστε τη μέθοδο render για να αποδώσετε την εφαρμογή σας
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
