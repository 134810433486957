import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';


const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false); // Κατάσταση για το checkbox

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://sweetleaf.gr/wp-json/jwt-auth/v1/token', {
                username,
                password,
            });
            const { token } = response.data;

            // Αποθήκευση του token
            if (rememberMe) {
                localStorage.setItem('token', token); // Αποθήκευση στο τοπικό αποθηκευτικό χώρο
            } else {
                sessionStorage.setItem('token', token); // Αποθήκευση μόνο για την τρέχουσα συνεδρία
            }

            onLogin(); // Κλήση της συνάρτησης onLogin
        } catch (error) {
            console.error('Login error:', error);
            alert('Σφάλμα κατά τη σύνδεση. Ελέγξτε τα στοιχεία σας.');
        }
    };

    return (
        <div className="login">
    <h2>Σύνδεση</h2>
    <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label>Όνομα Χρήστη:</label>
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />
        </div>
        <div className="form-group">
            <label>Κωδικός:</label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
        </div>
        <div className="form-group">
            <label>
                <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                />
                Θυμήσου με
            </label>
        </div>
        <button type="submit">Σύνδεση</button>
    </form>
</div>

    );
};

export default Login;
