import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProductList.css';

const API_URL = 'https://sweetleaf.gr/wp-json/wc/v3/products';
const CONSUMER_KEY = 'ck_20cb7ebbc919633bcdd92761f86108b80925dc68';
const CONSUMER_SECRET = 'cs_15fc5423ab551e020478405d63d7f0ebe0427398';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const fetchAllProducts = async () => {
        let allProducts = [];
        let page = 1;
        let totalPages = 1;

        try {
            do {
                const response = await axios.get(API_URL, {
                    params: {
                        per_page: 100,
                        page,
                    },
                    auth: {
                        username: CONSUMER_KEY,
                        password: CONSUMER_SECRET
                    }
                });

                allProducts = [...allProducts, ...response.data];
                totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
                page++;
            } while (page <= totalPages);

            setProducts(allProducts);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const updateProductStock = async (productId, inStock) => {
        try {
            await axios.put(`${API_URL}/${productId}`, 
                { stock_status: inStock ? 'instock' : 'outofstock' },
                {
                    auth: {
                        username: CONSUMER_KEY,
                        password: CONSUMER_SECRET
                    }
                }
            );
            fetchAllProducts();
        } catch (error) {
            console.error('Error updating product stock:', error);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchAllProducts();
        }
    }, [isLoggedIn]);

    const handleStockChange = (productId, event) => {
        const inStock = event.target.value === 'instock';
        updateProductStock(productId, inStock);
    };

    const groupedProducts = products.reduce((acc, product) => {
        product.categories.forEach(category => {
            if (!acc[category.id]) {
                acc[category.id] = {
                    id: category.id,
                    name: category.name,
                    products: []
                };
            }
            acc[category.id].products.push(product);
        });
        return acc;
    }, {});

    const groupedProductArray = Object.values(groupedProducts);

    return (
        <div className="product-list">
            <h1>Λίστα Προϊόντων</h1>
            {isLoggedIn ? (
                groupedProductArray.length > 0 ? (
                    groupedProductArray.map(group => (
                        <div key={group.id} className="category">
                            <h2>{group.name}</h2>
                            <div className="products">
                                {group.products.length > 0 ? (
                                    group.products.map(product => (
                                        <div key={product.id} className="product">
                                            <img src={product.images[0]?.src} alt={product.name} />
                                            <h3>{product.name}</h3>
                                            <p>{product.price} €</p>
                                            <p>Stock: {product.stock_status}</p>
                                            <select 
                                                value={product.stock_status === 'instock' ? 'instock' : 'outofstock'}
                                                onChange={(e) => handleStockChange(product.id, e)}
                                            >
                                                <option value="instock">In Stock</option>
                                                <option value="outofstock">Out of Stock</option>
                                            </select>
                                        </div>
                                    ))
                                ) : (
                                    <p>Δεν υπάρχουν προϊόντα σε αυτή την κατηγορία.</p>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Δεν υπάρχουν κατηγορίες προϊόντων.</p>
                )
            ) : (
                <p>Πρέπει να συνδεθείτε για να δείτε τα προϊόντα.</p>
            )}
        </div>
    );
};

export default ProductList;
